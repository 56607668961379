<template>
  <Card class="run-summary">
    <div class="top">
      <div>
        <Avatars v-if="users" :users="users" small />
      </div>
      <header>
        <h4>
          <UserList :users="users" />
        </h4>
        <span v-if="run.ended_at"
          >on {{ new Date(run.ended_at).toLocaleDateString() }}</span
        >
        <nuxt-link :to="`/runs/${run.id}`">
          <h3>
            {{ course?.name }}
          </h3>
        </nuxt-link>
      </header>
    </div>
    <nuxt-link :to="`/runs/${run.id}`">
      <div class="map-container">
        <StaticMap :width="600" :height="300" :geojson="geojson" />
      </div>
    </nuxt-link>
  </Card>
</template>
<script lang="ts" setup>
import type { GeoJsonObject } from "geojson";
import type { MapboxGeoJSONFeature } from "mapbox-gl";

import type { RunSummary } from "./RunFeed.vue";

type Checkpoint = RunSummary["courses"][number]["checkpoints"][number];
const course = computed(() => first(props.run.courses));
const props = defineProps<{
  run: RunSummary;
}>();

const users = computed(() => compact(props.run.users));
function first<T>(arg: T | T[]): T {
  if (Array.isArray(arg)) {
    if (!arg[0]) throw new Error("Couldn't grab first one");
    return arg[0];
  }
  return arg;
}
const geojson = computed(() => {
  const hits: Checkpoint[] = [];
  const misses: Checkpoint[] = [];

  course.value?.checkpoints?.forEach((c) => {
    if (props.run.checkins.find((checkin) => checkin.checkpoint_id == c.id))
      hits.push(c);
    else misses.push(c);
  });

  const collection = {
    type: "FeatureCollection",
    features: [],
  };

  if (misses.length > 0) {
    collection.features.push({
      type: "Feature",
      properties: {
        "marker-size": "m",
        "marker-symbol": "pitch",
        "marker-color": "gray",
      },
      geometry: {
        type: "MultiPoint",
        coordinates: misses.map(
          (c) =>
            c && [
              Number(Number(c.lng).toFixed(6)),
              Number(Number(c.lat).toFixed(6)),
            ]
        ),
      },
    });
  }

  if (hits.length > 0) {
    collection.features.push({
      type: "Feature",
      properties: {
        "marker-size": "m",
        "marker-symbol": "pitch",
        "marker-color": "green",
      },
      geometry: {
        type: "MultiPoint",
        coordinates: hits.map(
          (c) =>
            c && [
              Number(Number(c.lng).toFixed(6)),
              Number(Number(c.lat).toFixed(6)),
            ]
        ),
      },
    });
  }
  return collection;
});
</script>
<style scoped>
.card.run-summary {
  border-radius: var(--quarter-grid);
  background-color: var(--white);

  margin-bottom: var(--grid);
  padding: var(--grid);

  display: flex;
  flex-direction: column;
  gap: var(--half-grid);
}

.map-container {
  width: 100%;
  height: 300px;
  display: grid;
  place-content: center;
  overflow: hidden;
}

.top {
  display: grid;
  grid: auto/ 50px 1fr;
}
</style>

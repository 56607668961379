<template>
  <div v-if="query.isFetched">
    <div v-if="!query.isFetching && runs.length === 0" class="empty-state">
      <h3>Runs will appear here!</h3>
    </div>
    <div v-for="run in runs" :key="run.id">
      <RunSummary v-if="run" :run="run" />
    </div>
    <LoadMore
      :action="query.fetchNextPage"
      v-if="query.hasNextPage?.value === true"
    />
  </div>
  <ErrorView v-else-if="error" :error="error" />
</template>
<script lang="ts" setup>
import { useInfiniteQuery } from "@tanstack/vue-query";

const props = defineProps({
  userId: {
    type: Number,
    required: false,
  },
});

const supabase = useClient();
const query = useInfiniteQuery({
  queryKey: ["run-feed", props.userId],
  queryFn: async ({ pageParam }: { pageParam?: string }) => {
    return await supabase
      .rpc("run_feed", {
        runner_id: props.userId || null,
        ended_before: pageParam || null,
      })
      .limit(5)
      .select(
        `
        id,
        ended_at,
        courses ( name,
          checkpoints!checkpoints_course_id_fkey ( id, lat, lng, radius, name )
        ),
        users ( id, name, username, avatar_url ),
        checkins ( checkpoint_id ),
        run_placements ( placement )`
      )
      .then((d) => d.data);
  },
  getNextPageParam: (lastPage, _pages) => lastPage?.at(-1)?.ended_at,
});

const runs = computed(() => compact(query.data.value?.pages.flat()));

export type RunSummary = NonNullable<
  NonNullable<UnwrapRef<typeof runs>>[number]
>;
</script>
<style scoped>
.empty-state {
  padding: 2em 0;
}
</style>
